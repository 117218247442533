.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl{
    padding: 0;
}

html, body{
    height: 100%;
    /* background-color: #78C2FF; */
}

.btn-covid{
    background-color: #2581CE;
    border-color: #2581CE;
    border-radius: 15px;
    padding: 10px;
}